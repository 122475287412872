@import '@fremtind/jkl-core/core.min.css';
@import '@fremtind/jkl-loader/loader.min.css';
@import '@fremtind/jkl-text-input/text-input.min.css';
@import '@fremtind/jkl-icon-button/icon-button.min.css';
@import '@fremtind/jkl-toggle-switch/toggle-switch.min.css';
@import '@fremtind/jkl-card/card.min.css';

@import '~@fremtind/jkl-core/variables/_spacing.scss';

.app {
  margin: $layout-spacing--small;
  max-width: 500px;
}

.jkl-text-input--inline {
  margin: 0;
}
